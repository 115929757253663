import React from 'react';
import about from './images/about.png'

const AboutUs = () => {
    return (
        <div class="aboutus-wrapper">
            <div class="aboutus-content-left">
                <img src={about} alt=''/>
                <hr align='left'/>
                <h1>About Us</h1>
            </div>
            <div class="aboutus-content-right">
                <h1> Delivering Accurate and Reliable Audit and Assurance Services with a Commitment to Trust and Transparency</h1>
                <p>CREATIVE BUSINESS MANAGEMENT is a leading audit firm that delivers accurate and reliable financial reporting services. Our team consists of highly skilled and experienced auditors who provide personalized attention to each client. We understand our clients' unique needs and objectives, and provide tailored solutions that help them achieve their goals while meeting their financial reporting and compliance requirements. We stay up-to-date with the latest industry trends and best practices, and our commitment to ongoing learning ensures that we provide the most effective audit services possible. Contact us to learn more about our audit and assurance services and how we can help your business succeed.</p>
                <hr align="right"/>
            </div>

        </div>
    );
};

export default AboutUs;