import React from 'react';
import FooterModule from './FooterModule';
import OurServices from './OurServices';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';




const FullPageScroll = () => {


    return (
        <div class="scroll-snap-container" id="snapscroll" >

            <section id="AboutUs">
                <AboutUs/>
            </section>

            <section id="OurServices">
                <OurServices/>
            </section>
            <section id="ContactUs">
                <ContactUs/>
            </section>
            <section >
                <FooterModule/>
            </section>
        </div>
    );
};

export default FullPageScroll;