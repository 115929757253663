import React from 'react';

const ContactUs = () => {
    return (
        <div class="contact-us-wrapper">
            <div class="contactus-content-left">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.0026920218165!2d85.27340057462689!3d27.686311676420473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb187427374a8b%3A0xe5bfb9da481e995f!2sMaitrinagar%2C%20Kirtipur%2044600!5e0!3m2!1sen!2snp!4v1683039991453!5m2!1sen!2snp" class="map" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            <div class="contactus-content-right">
                <div class="contactus-card">
                    <div>
                            <p><span class="material-symbols-outlined blue">distance</span> Kirtipur - 02, Maitrinagar</p>
                            <p><span class="material-symbols-outlined blue">phone_in_talk</span> 951138086</p>
                            <p><span class="material-symbols-outlined blue">alternate_email</span> creativitybusiness074@gmail.com</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;