import './App.css';
import '@fortawesome/fontawesome-free/js/all.js';
import Navbar from './navbr';
import Landing from './landing';


import FullPageScroll from './FullPageScroll';


function App() {
  return (
    <div>
      <Navbar></Navbar>
      
      <Landing />
      
      
      <FullPageScroll></FullPageScroll>
    </div>
  
  );
}

export default App;
