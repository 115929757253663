import React, { useState } from 'react';
import logo from './images/logo.png';

const Landing = () => {

    const[height, setHeight] = useState(false);
    const changeHeight = () => {
        if (window.scrollY >= 90) {
            setHeight(true)
        }

        else {
            setHeight(false)
        }
    }

    window.addEventListener('scroll', changeHeight)

    return (
        <div id="Home" class={height ? 'height hactive' : 'height'}>
        <div class="landingwrapper">
        <div class='landing'>
            <div class="landing-content">
                <img src={logo} alt='logo'/>
                <div class="tagline">
                    <div class="tagline-left"><h1>sano hos( ya #¦loú</h1></div>
                    <div class="tagline-right"><h1>tr AaFnW hos(</h1> </div>
                     </div>
                <hr/>
                <div class="landing-descriptions"> 
                    <h1>Creativity Welcomes You</h1>
                    <p>For Auditing, Taxation & Financial Advisory Servies </p></div>
            </div>
        </div>
        </div>
        </div>
    );
};

export default Landing;