import React from 'react';

const FooterModule = () => {
    return (
        <div>
            <div class="top">
                    <div class="footer-col3">
                        <h4>About Us</h4>
                        <p><bold>Creativity Business Management (CBM)</bold> is an accounting & assurance firm based in Kathmandu, Nepal established with motive to grow your business financially sound.</p>
                        <p>Our strength liesin ability to understand our clients need and create customized solutions.</p>
                    </div>
                    <div class="footer-col3">
                        <h4>Useful links</h4>
                        <ul>
                            <li>Inland Revenue Department</li>
                            <li>Ministry of Finance</li>
                            <li>Nepal Rastra bank</li>

                        </ul>
                    </div>

                    <div class="footer-col3">
                        <div>
                            <h3>CREATIVE BUSINESS MANAGEMENT</h3>
                            <p><span class="material-symbols-outlined">distance</span> Kirtipur - 02, Maitrinagar</p>
                            <p><span class="material-symbols-outlined">phone_in_talk</span> 951138086</p>
                            <p><span class="material-symbols-outlined">alternate_email</span> creativitybusiness074@gmail.com</p>
                        </div>
                    </div>
                </div>
                <div class="middle">
                    <h2>Follow Us</h2>
                    <div class="socialmedialogos"> 
                        <div class="socialmedia">
                            <i class="fab fa-facebook-f"></i> 
                            <div class="socialmediahandle">Facebook Handle</div>
                        </div>
                        <div class="socialmedia">
                            <i class="fab fa-instagram"></i> 
                            <div class="socialmediahandle">Instagram Handle</div>
                        </div>
                    </div>
                </div>
                <div class="bottom">
                &#169; {new Date().getFullYear()} Creativity Business Management. Made with <i class="fas fa-heart textred"></i> by Pegasus DH
                </div>
        </div>
    );
};

export default FooterModule;