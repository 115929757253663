import React, { useState } from 'react';
import logo from './images/logo.png';


const Navbr = () => {
    // change nav bg on scroll

    

    const [color, setColor] = useState(false);
    const changeColor = () => {
        if (window.scrollY >= 90) {
            setColor(true)
        }

        else {
            setColor(false)
        }
    }

    window.addEventListener('scroll', changeColor)

    const [brand, setBrand] = useState(true);
    const changeBrand = () => {
        if (window.scrollY >= 90) {
            setBrand(true)
        }

        else {
            setBrand(false)
        }
    }

    window.addEventListener('scroll', changeBrand);

    return (
        <div>
        <div className={color ? "navwrap active" : "navwrap"}>
            <div class="navbar">
                <div class="navbar-brand">
                    <div class={brand ? 'iactive' : 'iscrollhide'}>
                        <img src={logo}  alt=''/>
                    </div>
                </div>
                <div class="navbar-nav">
                    <ul>
                        <li><a href="#Home" alt='' data-tooltip= 'Home'>Home</a></li>
                        <li><a href="#AboutUs" alt='' data-tooltip= 'About Us'>About Us</a></li>
                        <li><a href="#OurServices" alt='' data-tooltip= 'Our Services'>Our Services</a></li>
                        <li><a href="#ContactUs" alt='' data-tooltip= 'Contact Us'>Contact Us</a></li>
                        
                    </ul>
                </div>
            </div>

            
        </div>
        <div class="mobilenav">
        <div class="mobile">
                    <ul>
                        <li><a href="#Home" alt=''><i class="fas fa-home"></i></a>Home</li>
                        <li><a href="#AboutUs" alt='' data-tooltip= 'About Us'><i class="fas fa-building"></i></a>About Us</li>
                        <li><a href="#OurServices" alt='' data-tooltip= 'Our Services'><i class="fas fa-server"></i></a>Services</li>
                        <li><a href="#ContactUs" alt='' data-tooltip= 'Contact Us'><i class="fas fa-id-card-alt"></i></a>Contact us</li>
                        
                    </ul>
                </div>
        </div>
        </div>
    );
};

export default Navbr;