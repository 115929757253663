import React from 'react';


const OurServices = () => {
    return (
        
        <div class="our-services">
            <h1>Our Services</h1>
            <hr/>
            <div>
            <div class="col">
                    <div class="ourservices-content-top">
                    <i class="fas fa-percentage"></i>
                    </div>
                    <div class="ourservices-content-bottom">
                        <h2>Audit & Assurance</h2>
                        <p>With increasing business complexities, our clients need to ensure that the financial statements provide a true and fair view of their performance.</p>
                    </div>


                </div>
            <div class="col">
                    <div class="ourservices-content-top">
                    <i class="fas fa-percentage"></i>
                    </div>
                    <div class="ourservices-content-bottom">
                        <h2>Taxation</h2>
                        <p>Managing tax complexity to maximize value is a key consideration of every major business decision.</p>
                    </div>


                </div>
                <div class="col">
                    <div class="ourservices-content-top">
                    <i class="fas fa-percentage"></i>
                    </div>
                    <div class="ourservices-content-bottom">
                        <h2>Risk Advisory</h2>
                        <p>We help enhance your business performance by building strong risk management frameworks into the very core of your business processes.</p>
                    </div>


                </div>
                
            
            </div>


        </div>
    );
};

export default OurServices;